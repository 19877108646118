
import { defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'Header',
  setup() {
    const route = useRoute()
    const router = useRouter()
    return {
      route,
      router
    }
  }
})
