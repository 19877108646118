<template lang="html">
  <div>
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Header from "@cp/Header/index.vue";
import Footer from "@cp/Footer/index.vue";
export default defineComponent({
  name: "index",
  components: { Header, Footer },
});
</script>
<style lang="less"></style>
